import { GrClose } from "react-icons/gr";
import { LiaPencilRulerSolid } from "react-icons/lia";
import { DEFAULTS } from "../../../constants/defaults";
import { MENU } from "../../../constants/menus";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import ToolMenu from "../shared/ToolMenu";
import SceneTypesSidebar from "../scenes/SceneTypesSidebar";

const MeasurementMenu = () => {
  const { isRightSideMenuOpen } = useToggle();

  return (
    <Wrapper
      elementType="section"
      classNames={`z-30 text-primary fixed bottom-6 right-[50px]
      ${!isRightSideMenuOpen ? "h-0 delay-100" : "h-[220px]"}
      `}
    >
      <MenuToggle />

      <Wrapper
        classNames={`circle relative rounded-full items-center justify-center flex transition-all transform w-[220px] h-[220px] 
        ${
          isRightSideMenuOpen
            ? "translate-x-0"
            : "scale-0 translate-x-[50%] translate-y-[50%] h-0 w-0"
        }
        `}
      >
        <ToolMenu
          menuLabel="measure"
          buttons={MENU.Measure}
          classNames={`fixed top-0`}
        />
        <ToolMenu
          menuLabel="view"
          buttons={MENU.View}
          classNames={`fixed left-0 top-1/2 -translate-x-1/2 -translate-y-1/2`}
        />
        <ToolMenu
          menuLabel="navigate"
          buttons={MENU.Navigate}
          classNames={`fixed right-0 top-1/2 translate-x-1/2 -translate-y-1/2`}
        />
        <ToolMenu
          menuLabel="keynote"
          buttons={MENU.Keynote}
          classNames={`fixed bottom-0 left-0`}
        />
        <ToolMenu
          menuLabel="component"
          buttons={MENU.Component}
          classNames={`fixed bottom-0 right-0`}
        />
      </Wrapper>
    </Wrapper>
  );
};

const MenuToggle = () => {
  const { isRightSideMenuOpen, setIsRightSideMenuOpen, potreeDisplayMode } =
    useToggle();

  const theme =
    potreeDisplayMode === DEFAULTS.potreeDisplayTab ? "light" : "dark";

  return (
    <Wrapper
      classNames={`absolute z-40 transition-all transform ${
        isRightSideMenuOpen
          ? "left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          : "bottom-0 -right-[24px]"
      }`}
    >
      {isRightSideMenuOpen ? (
        <button
          onClick={() => setIsRightSideMenuOpen(() => !isRightSideMenuOpen)}
          className={`group flex items-center gap-8 p-3 rounded-full shadow-2xl hover:scale-100 transform transition 
          ${theme === "light" ? "bg-primary" : "bg-white"}
          ${isRightSideMenuOpen ? "scale-75" : "scale-0"}
          `}
        >
          <GrClose
            size={18}
            className={`group-hover:scale-125 transition-all 
            ${theme === "light" ? "text-white" : "text-primary"}
            `}
          />
        </button>
      ) : (
        <button
          onClick={() => setIsRightSideMenuOpen(() => !isRightSideMenuOpen)}
          className={`group flex items-center gap-8 p-3 rounded-full shadow-2xl transform transition 
          ${theme === "light" ? "bg-primary" : "bg-white"}
          ${isRightSideMenuOpen ? "scale-50" : "scale-100"}
          `}
        >
          <LiaPencilRulerSolid
            size={24}
            className={`group-hover:scale-125 transition-all 
            ${theme === "light" ? "text-white" : "text-primary"}
            `}
          />
        </button>
      )}
    </Wrapper>
  );
};

const RightSidebar = () => {
  const { potreeViewer } = usePotree();

  if (!potreeViewer) return null;

  return (
    <Wrapper elementType="aside">
      <SceneTypesSidebar />
      <MeasurementMenu />
    </Wrapper>
  );
};

export default RightSidebar;
