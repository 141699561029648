import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { Theme } from "../../types";

interface ButtonProps {
  theme?: Theme;
  label?: string;
  icon?: IconType;
  handleClick: () => void;
  type?: "submit" | "reset" | "button";
  classNames?: string;
}

const DEFAULT_ICON = {
  size: 16,
  color: "text-white group-hover:text-secondary",
};

const Button: React.FC<ButtonProps> = ({
  label,
  icon: Icon,
  handleClick,
  type = "button",
  classNames,
  theme = "dark",
}) => {
  const { t } = useTranslation();

  if (!Icon && !label) return null;

  return (
    <button
      type={type}
      onClick={handleClick}
      className={`group capitalize rounded-lg
        ${
          theme === "dark"
            ? "bg-gray text-white hover:text-secondary"
            : "bg-lgray text-white hover:text-secondary"
        } 
        ${Icon ? "px-2 py-1" : "px-8 py-2"}
        ${classNames ?? ""}
      `}
    >
      {Icon && <Icon size={DEFAULT_ICON.size} className={DEFAULT_ICON.color} />}
      {label && t(`${label}`)}
    </button>
  );
};

export default Button;
