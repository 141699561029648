import { Outlet } from "react-router-dom";
import Footer from "../components/admin/Footer";
import Container from "../components/shared/Container";
import AdminHeader from "../components/admin/AdminHeader";

const AdminLayout: React.FC = () => {
  return (
    <div className="bg-primary text-white text-sm-regular flex flex-col min-h-screen min-w-screen">
      <AdminHeader />
      <main className="flex-grow">
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default AdminLayout;
