import { useTranslation } from "react-i18next";
import { DEFAULTS } from "../../../constants/defaults";
import { Theme } from "../../../types";
import { useToggle } from "../../../hooks/useToggle";
import ToolButton from "./ToolButton";

interface ToolMenuProps {
  theme?: Theme;
  menuLabel: string;
  buttons: {
    toolName: string;
    action: string | null;
    toolIconSrc: string;
  }[];
  vertical?: boolean;
  classNames?: string;
}

const ToolMenu: React.FC<ToolMenuProps> = ({
  theme = "light",
  menuLabel,
  buttons,
  vertical,
  classNames,
}) => {
  const { t } = useTranslation();
  const { potreeDisplayMode } = useToggle();

  theme = potreeDisplayMode === DEFAULTS.potreeDisplayTab ? "light" : "dark";

  return (
    <div
      className={`shadow-2xl transition-all flex flex-col items-end gap-0 ${
        classNames ?? ""
      }`}
    >
      <p
        className={`capitalize absolute -top-6 left-1/2 transform -translate-x-1/2 ${
          theme === "light" ? "text-primary" : "text-white"
        }`}
      >
        {menuLabel && t(`${menuLabel}`)}
      </p>
      <div
        className={`gap-1 px-2 rounded-xl overflow-hidden flex 
        ${theme === "light" ? "bg-primary" : "bg-white"} 
        ${vertical ? "flex-col" : "flex-row"}`}
      >
        {buttons.map((button, index) => (
          <ToolButton
            key={`tool-menu_${button.toolName}_${index}`}
            toolName={button.toolName}
            action={button.action}
            toolIconSrc={button.toolIconSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default ToolMenu;
