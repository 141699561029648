import { toast } from "react-toastify";
import { GoPlus } from "react-icons/go";
import { Note } from "../../../types";
import { authTokenHeader } from "../../../utils/helper";
import { useAuth } from "../../../hooks/useAuth";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import PopupMenu from "../../shared/PopupMenu";
import Button from "../../shared/Button";
import Wrapper from "../shared/Wrapper";
import Label from "../../shared/Label";

const Notes = () => {
  const { token } = useAuth();
  const { theme, project, setProject } = usePotree();
  const { setIsOpenSubmenu, setEditNoteFormId } = useToggle();

  const deleteNote = async (noteId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/note/${noteId}`,
        {
          method: "DELETE",
          headers: authTokenHeader(token),
        }
      );

      const deletedNote = await response.json();

      if (deletedNote) {
        console.log({ deletedNote });

        toast.success("Note deleted successfully.");

        setProject((prevProject: any) => ({
          ...prevProject,
          field_notes: prevProject.field_notes.filter(
            (item: Note) => item.cid !== noteId
          ),
        }));

        return;
      }

      toast.error("Deleting note failed.");
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const handleDeleteNote = (id: string) => {
    deleteNote(id);
    setEditNoteFormId(null);
    setIsOpenSubmenu(false);
  };

  const handleEditNote = (id: string) => {
    setEditNoteFormId(id);
    setIsOpenSubmenu(true);
  };

  return (
    <Wrapper classNames="flex flex-col gap-4">
      <Wrapper classNames="flex flex-row gap-2">
        <Label text="notes" classNames="text-base-regular text-primary" />

        <Button
          icon={GoPlus}
          handleClick={() => {
            setIsOpenSubmenu(true);
            setEditNoteFormId("new");
          }}
          theme={theme}
        />
      </Wrapper>

      <Wrapper classNames="flex flex-col gap-2">
        {project &&
          project.field_notes.map((note) => {
            if (!note.subject || !note.cid) return null;

            return (
              <div
                key={note.cid}
                id={note.cid}
                className="flex flex-row gap-4 justify-between"
              >
                <p
                  className="flex flex-grow hover:underline cursor-pointer"
                  onClick={() => {
                    setEditNoteFormId(note.cid);
                    setIsOpenSubmenu(true);
                  }}
                >
                  {note.subject}
                </p>

                <PopupMenu
                  theme={theme}
                  id={note.cid}
                  handleEdit={handleEditNote}
                  handleDelete={handleDeleteNote}
                  handleShare={(id) => {
                    console.log(`Share note: ${id}`);
                  }}
                />
              </div>
            );
          })}
      </Wrapper>
    </Wrapper>
  );
};

export default Notes;
