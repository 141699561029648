import { useTranslation } from "react-i18next";
import { GrDown } from "react-icons/gr";
import { Theme } from "../../types";

const locales: {
  [key: string]: {
    title: string;
  };
} = {
  en: { title: "EN" },
  de: { title: "DE" },
};

const LanguageSwitcher = ({
  theme = "dark",
  classNames,
}: {
  theme?: Theme;
  classNames?: string;
}) => {
  const { i18n } = useTranslation();

  return (
    <div className="group flex flex-row items-center">
      <select
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        className={`appearance-none focus:none outline-none hover:cursor-pointer py-2 px-4 pr-10 rounded-lg
        ${
          theme === "dark"
            ? "bg-gray text-white group-hover:text-secondary"
            : "bg-lgray text-white group-hover:text-secondary"
        } 
        ${classNames ?? ""}
      `}
      >
        {Object.keys(locales).map((locale) => (
          <option value={locale} key={`language_switcher_item_${locale}`}>
            {locales[locale].title}
          </option>
        ))}
      </select>
      <div className="pointer-events-none relative h-0 w-0 bg-red-500 flex items-center justify-center">
        <GrDown
          size={16}
          className={`absolute -left-8 ${
            theme === "dark"
              ? "bg-gray text-white group-hover:text-secondary"
              : "bg-lgray text-white group-hover:text-secondary"
          }`}
        />
      </div>
    </div>
  );
};

export default LanguageSwitcher;
