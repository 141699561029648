import { useCallback, useEffect, useRef } from "react";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import Error from "../../shared/Error";

const PotreeViewer = () => {
  const { project, potreeViewer, setPotreeViewer, setProjectPcs } = usePotree();
  const json5 = project?.field_json5 ?? null;

  const potreeContainerDiv = useRef(null);

  const initializeViewer = useCallback(() => {
    const Potree = (window as any).Potree;
    const viewerElem = potreeContainerDiv.current;

    if (!Potree || !viewerElem || !json5) {
      return
    }

    // Update potree viewer in global react context
    const viewer = new Potree.Viewer(viewerElem);
    (window as any).potreeViewer = viewer;

    // Load Potree project by json5
    Potree.loadProject(viewer, json5);

    // On each pointcloud added to potree view, pointcloud_added lister executes.
    viewer.scene.addEventListener("pointcloud_added", ({ pointcloud }: any) => {
      console.log(`Pointclod ${pointcloud.name} loaded...`);
      setPotreeViewer(() => viewer);
    });
  }, [json5, setPotreeViewer]);

  useEffect(() => {
    // When all pointclouds are added on first load update them in a global
    // state to handle checkbox to visible/hide pointclouds
    if (potreeViewer) {
      potreeViewer.scene.pointclouds.map((pointcloud: any) =>
        setProjectPcs((prev: any) => [
          ...prev,
          {
            label: pointcloud.name,
            id: pointcloud.o_id,
            visibile: true,
          },
        ])
      );
    }
  }, [potreeViewer, setProjectPcs]);

  useEffect(() => {
    initializeViewer();
  }, [initializeViewer]);

  if (!json5) return <Error message="Missing pointcloud URL." />;

  return (
    <Wrapper classNames="potree_container">
      <div ref={potreeContainerDiv} id="potree_render_area"></div>
    </Wrapper>
  );
};

export default PotreeViewer;
