import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Project } from "../../types";
import { DEFAULTS } from "../../constants/defaults";
import PopupMenu from "../shared/PopupMenu";

const ProjectsList = ({ projects }: { projects: Project[] }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tableHeadContent = (
    <tr>
      <th scope="col" className="text-white pr-4 py-2">
        #
      </th>
      <th scope="col" className="capitalize px-4 py-2">
        {t("project")}
      </th>
      <th scope="col" className="capitalize px-4 py-2">
        {t("location")}
      </th>
      <th scope="col" className="capitalize px-4 py-2">
        {t("created")}
      </th>
      <th scope="col" className="capitalize px-4 py-2">
        {t("updated")}
      </th>
      <th scope="col"></th>
    </tr>
  );

  let tableBodyContent;
  if (!projects || projects.length <= 0) {
    tableBodyContent = (
      <tr className="border-b border-gray">
        <td className="py-2">No results found.</td>
      </tr>
    );
  } else {
    tableBodyContent = projects.map((project: Project, index: number) => (
      <tr
        key={`project_${project.id}_${index}`}
        className="border-b border-gray"
      >
        <th scope="row" className="pr-4 py-2 w-2">
          {index + 1}
        </th>

        <td className="px-4 py-2 hover:underline">
          <Link
            to={`/project/${project.id}`}
            className="visited:text-white flex"
          >
            {project.label}
          </Link>
        </td>

        <td className="px-4 py-2">Berlin</td>
        <td className="px-4 py-2">
          {dayjs(Number(project.created) * 1000).format(
            DEFAULTS.formatDateTime
          )}
        </td>
        <td className="px-4 py-2">
          {dayjs(Number(project.changed) * 1000).format(
            DEFAULTS.formatDateTime
          )}
        </td>
        <td className="pl-4 py-2 w-2">
          <PopupMenu
            id={project.id}
            handleEdit={() => navigate(`/project/${project.id}/edit`)}
            handleShare={() => console.log(`Archive project: ${project.id}`)}
            shareLabel="archive"
          />
        </td>
      </tr>
    ));
  }

  return (
    <table className="w-full text-left">
      <thead className="text-secondary">{tableHeadContent}</thead>
      <tbody>{tableBodyContent}</tbody>
    </table>
  );
};

export default ProjectsList;
