import { toast } from "react-toastify";
import { VscArrowRight } from "react-icons/vsc";
import { GoPlus } from "react-icons/go";
import { HiOutlinePlus, HiOutlineMinus } from "react-icons/hi2";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { DEFAULTS } from "../../../constants/defaults";
import { PotreeDisplayModes, OrtoScene } from "../../../types";
import { potreeTools } from "../../../utils/potreeTools";
import { authTokenHeader } from "../../../utils/helper";
import { useAuth } from "../../../hooks/useAuth";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import Accordion from "../shared/Accordion";
import Input from "../shared/Input";
import Button from "../../shared/Button";
import PopupMenu from "../../shared/PopupMenu";
import Label from "../../shared/Label";
import Authenticated from "../shared/Authenticated";
import { OrtoSceneHelper } from "../../../utils/OrtoSceneHelper";

const DEFAULT_NEW_SCENE = {
  label: "",
  showForm: false,
};

const DEFAULT_EDIT_SCENE: {
  label: string;
  showFormId: string | null;
} = {
  label: "",
  showFormId: null,
};

interface SceneTypeAccordionProps {
  sceneTypeLabel: string;
  sceneTypeKey: string;
  sceneTypeScenes: OrtoScene[];
}

const SceneTypeAccordion: React.FC<SceneTypeAccordionProps> = ({
  sceneTypeLabel,
  sceneTypeKey,
  sceneTypeScenes,
}) => {
  const { token } = useAuth();
  const { potreeDisplayMode, activeScene, setActiveScene } = useToggle();
  const { project, setProject, potreeViewer, setProjectPcs } = usePotree();

  const theme =
    potreeDisplayMode === DEFAULTS.potreeDisplayTab ? "light" : "dark";

  const [newScene, setNewScene] = useState(DEFAULT_NEW_SCENE);
  const [editScene, setEditScene] = useState(DEFAULT_EDIT_SCENE);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [activeSceneIndex, setActiveSceneIndex] = useState<number | null>(null);

  // Use a ref to reference the input field
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setErrors({});

    // When add new scene or update scene form is visible, focus on the label input field
    if ((newScene.showForm || editScene.showFormId) && inputRef.current) {
      inputRef.current.focus();
    }
  }, [newScene.showForm, editScene.showFormId]);

  const handleViewScene = (scene: OrtoScene) => {
    if (!scene) {
      return
    }

    OrtoSceneHelper.setScene(potreeViewer, scene, setProjectPcs)

    setNewScene(DEFAULT_NEW_SCENE);
    setEditScene(DEFAULT_EDIT_SCENE);
    setActiveScene(scene.id ?? null);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const displayModeScenes =
        project?.field_scenes[potreeDisplayMode]?.scenes;

      if ((e.key === "ArrowUp" || e.key === "ArrowDown") && displayModeScenes) {
        e.preventDefault();

        const currentIndex = displayModeScenes.findIndex(
          (scene) => scene.id === activeScene
        );

        let newIndex;
        if (e.key === "ArrowUp") {
          newIndex =
            currentIndex === 0
              ? displayModeScenes.length - 1
              : currentIndex - 1;
        } else {
          newIndex =
            currentIndex === displayModeScenes.length - 1
              ? 0
              : currentIndex + 1;
        }

        const targetScene = displayModeScenes[newIndex];

        if (targetScene) {
          setActiveSceneIndex(newIndex);
          setActiveScene(targetScene.id);
          handleViewScene(targetScene);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  // eslint-disable-next-line
  }, [activeSceneIndex, activeScene, potreeDisplayMode, setActiveScene]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    isEditSceneInput = false
  ) => {
    setErrors({});
    isEditSceneInput
      ? setEditScene((prev) => ({
          ...prev,
          label: e.target.value,
        }))
      : setNewScene((prev) => ({
          ...prev,
          label: e.target.value,
        }));
  };

  const addScene = async () => {
    // TODO Duplicated code. Move to OrtoSceneHelper?
    const body: any = JSON.stringify(OrtoSceneHelper.getScene(potreeViewer, newScene.label, sceneTypeKey));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/project/${project?.id}/scene`,
        {
          method: "PUT",
          headers: authTokenHeader(token),
          body,
        }
      );

      const newScene = await response.json();

      if (newScene && response.ok) {
        toast.success(`Scene '${newScene.label}' added.`);

        setProject((prevProject: any) => ({
          ...prevProject,
          field_scenes: {
            ...prevProject.field_scenes,
            [sceneTypeKey]: {
              ...prevProject.field_scenes[sceneTypeKey],
              scenes: [
                ...prevProject.field_scenes[sceneTypeKey].scenes,
                newScene,
              ],
            },
          },
        }));

        return;
      }

      toast.error(`Adding scene '${newScene.label}' failed.`);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const updateScene = async (sceneId: string, updatedSceneLabel: string) => {
    // TODO Duplicated code - 3rd edition?
    const body: any = JSON.stringify(OrtoSceneHelper.getScene(potreeViewer, updatedSceneLabel, sceneTypeKey));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${sceneId}`,
        {
          method: "POST",
          headers: authTokenHeader(token),
          body,
        }
      );

      const updatedScene = await response.json();

      if (updatedScene && response.ok) {
        toast.success(`Scene '${updatedSceneLabel}' updated.`);

        setProject((prevProject: any) => ({
          ...prevProject,
          field_scenes: {
            ...prevProject.field_scenes,
            [sceneTypeKey]: {
              ...prevProject.field_scenes[sceneTypeKey],
              scenes: prevProject.field_scenes[sceneTypeKey].scenes.map(
                (scene: OrtoScene) =>
                  scene.id === sceneId ? updatedScene : scene
              ),
            },
          },
        }));

        return;
      }

      toast.error(`Updating scene '${updatedScene}' failed.`);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const deleteScene = async (sceneId: string) => {
    const deletedSceneLabel = project?.field_scenes
      ? project?.field_scenes[sceneTypeKey as PotreeDisplayModes]?.scenes.find(
          (scene) => scene.id === sceneId
        )?.label
      : null;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/scene/${sceneId}`,
        {
          method: "DELETE",
          headers: authTokenHeader(token),
        }
      );

      const deletedScene = await response.json();

      if (deletedScene && response.ok) {
        toast.success(`Scene '${deletedSceneLabel}' deleted.`);

        setProject((prevProject: any) => ({
          ...prevProject,
          field_scenes: {
            ...prevProject.field_scenes,
            [sceneTypeKey]: {
              ...prevProject.field_scenes[sceneTypeKey],
              scenes:
                prevProject.field_scenes[sceneTypeKey]?.scenes.filter(
                  (scene: OrtoScene) => scene.id !== sceneId
                ) || [],
            },
          },
        }));

        return;
      }

      toast.error(`Deleting scene '${deletedSceneLabel}' failed.`);
    } catch (error: any) {
      console.log("API request failed:", error.message);
      throw Error(error.message);
    }
  };

  const handleAddScene = () => {
    if (!newScene.label.trim()) {
      setErrors((prev) => ({
        ...prev,
        label: "Scene label is required.",
      }));

      return;
    }

    addScene();

    setNewScene(DEFAULT_NEW_SCENE);
    setEditScene(DEFAULT_EDIT_SCENE);
  };

  const handleUpdateScene = (sceneId: string) => {
    if (!editScene.label.trim()) {
      setErrors((prev) => ({
        ...prev,
        label: "Scene label is required.",
      }));

      return;
    }

    updateScene(sceneId, editScene.label);

    setNewScene(DEFAULT_NEW_SCENE);
    setEditScene(DEFAULT_EDIT_SCENE);
  };

  const scenes = (
    <Wrapper classNames="flex flex-col gap-3">
      {sceneTypeScenes.map((scene) => (
        <div key={`scene-${scene.id}`}>
          {editScene.showFormId === scene.id ? (
            <Authenticated>
              <div className="relative">
                <Input
                  name="label"
                  value={editScene.label}
                  placeholder="Label..."
                  handleInputChange={(e) => handleInputChange(e, true)}
                  error={errors.label}
                  classNames="h-12"
                  ref={inputRef}
                />
                <button
                  className="absolute top-3 right-3 hover:scale-125"
                  onClick={() => handleUpdateScene(scene.id)}
                >
                  <VscArrowRight size={24} className="text-primary" />
                </button>
              </div>
            </Authenticated>
          ) : (
            <div className="flex flex-row gap-4 justify-between">
              <div onClick={() => handleViewScene(scene)}>
                <Label
                  text={scene.label}
                  theme={theme}
                  classNames={`cursor-pointer 
                  ${activeScene === scene.id ? "underline" : ""}
                  `}
                />
              </div>

              <Authenticated>
                <PopupMenu
                  key={`popup_memu_edit_scene_${scene.id}`}
                  theme={theme}
                  id={scene.id}
                  handleEdit={() => {
                    handleViewScene(scene);
                    setEditScene(() => ({
                      label: scene.label,
                      showFormId: scene.id,
                    }));
                  }}
                  handleDelete={() => deleteScene(scene.id)}
                />
              </Authenticated>
            </div>
          )}
        </div>
      ))}

      <Authenticated>
        <Wrapper classNames="relative">
          {newScene.showForm ? (
            <>
              <Input
                name="label"
                value={newScene.label}
                placeholder="Label..."
                handleInputChange={handleInputChange}
                error={errors.label}
                classNames="h-12"
                ref={inputRef}
              />
              <button
                className="absolute top-3 right-3 hover:scale-125"
                onClick={handleAddScene}
              >
                <VscArrowRight size={24} className="text-primary" />
              </button>
            </>
          ) : (
            <Button
              icon={GoPlus}
              handleClick={() => {
                setNewScene((prev) => ({
                  ...prev,
                  showForm: !prev.showForm,
                }));
                setEditScene(DEFAULT_EDIT_SCENE);
              }}
              theme={theme}
            />
          )}
        </Wrapper>
      </Authenticated>
    </Wrapper>
  );

  return potreeDisplayMode === sceneTypeKey ? (
    <Accordion
      label={sceneTypeLabel}
      body={scenes}
      defaultOpen={true}
      labelColor="text-lgray"
      openAccordionIcon={HiOutlineMinus}
      closeAccordionIcon={HiOutlinePlus}
    />
  ) : null;
};

export default SceneTypeAccordion;
