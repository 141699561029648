import { OrtoScene, PointCloudOctree, SceneDataPointcloud } from "../types";
import { DEFAULTS } from "../constants/defaults";

/**
 * Orto scene related stuff.
 */
export class OrtoSceneHelper {

  /**
   * Gets JSON data for a scene entity. Can be used to create or update a scene.
   *
   * @param viewer
   *   Potree viewer.
   * @param label
   *   Scene label.
   * @param sceneTypeKey
   *   Scene type.
   *
   *  @return OrtoScene
   */
  static getScene(viewer: any, label: string, sceneTypeKey: string) {
    const volumesData: any = OrtoSceneHelper.getVolumesData(viewer);
    const viewData: any = OrtoSceneHelper.getViewData(viewer);
    const pointcloudsData = OrtoSceneHelper.getPointcloudsData(viewer);
    const measurementsData = OrtoSceneHelper.getMeasurementsData(viewer);

    return {
      label: label,
      field_scene_type: sceneTypeKey,
      json_data: [
        {
          type: "attributes",
          value: {
            volumes: volumesData,
            view: viewData,
            pointclouds: pointcloudsData,
            measurements: measurementsData,
            background: viewer.background,
            cameraMode: viewer.scene.cameraMode,
            useHQ: viewer.useHQ,
            useEDL: viewer.useEDL,
            // controls
            // viewer theme: dark/light
          },
        },
      ],
    };
  }

  static setScene(viewer: any, ortoScene: OrtoScene, setProjectPcs: any) {
    ortoScene?.json_data.forEach((field_item: any) => {
      const json_data_item = field_item.value;
      if (json_data_item.view) {
        OrtoSceneHelper.setViewData(viewer, json_data_item.view);
      }
      if (json_data_item.volumes) {
        OrtoSceneHelper.setVolumesData(viewer, json_data_item.volumes);
      }
      if (json_data_item.pointclouds) {
        OrtoSceneHelper.setPointcloudsData(viewer, json_data_item.pointclouds, setProjectPcs)
      }
      if (json_data_item.measurements) {
        OrtoSceneHelper.setMeasurementsData(viewer, json_data_item.measurements);
      }
      if (json_data_item.cameraMode !== undefined) {
        viewer.scene.cameraMode = json_data_item.cameraMode;
      }
      if (json_data_item.background !== undefined) {
        viewer.setBackground(json_data_item.background);
      }
      if (json_data_item.useHQ !== undefined) {
        viewer.useHQ = json_data_item.useHQ;
      }
      if (json_data_item.useEDL !== undefined) {
        viewer.useEDL = json_data_item.useEDL;
      }
    });
  }

  /**
   * Gets pointclouds data for Orto scene json_data.
   */
  static getPointcloudsData(viewer: any): SceneDataPointcloud[] {
    return viewer.scene.pointclouds.map(
      (pc: PointCloudOctree) => ({
        o_id: pc.o_id,
        visible: pc.visible,
        material: {
          minSize: pc.material.minSize,
          opacity: pc.material.opacity,
          rgbGamma: pc.material.rgbGamma,
          rgbBrightness: pc.material.rgbBrightness,
          rgbContrast: pc.material.rgbContrast,
        },
      })
    );
  }

  /**
   * Sets pointcloud attributes from Orto scene json_data.
   *
   * @param viewer
   *   Potree viewer reference.
   * @param pointcloudsData
   * @param setProjectPcs
   *   ...
   */
  static setPointcloudsData(viewer: any, pointcloudsData: any[], setProjectPcs: any) {
    const pcDataById: any[] = [];
    pointcloudsData.forEach((data: any) => {
      pcDataById[data.o_id] = data;
    });
    // Update pointcloud visiblity in scene.
    viewer.scene.pointclouds.forEach((pc: PointCloudOctree) => {
      const pcData = pcDataById[pc.o_id];
      if (!pcData) {
        return;
      }

      pc.visible = pcData.visible;
      if (pcData.material) {
        const mat = pc.material;
        mat.minSize = pcData.material.minSize;
        mat.opacity = pcData.material.opacity;
        mat.rgbGamma = pcData.material.rgbGamma;
        mat.rgbBrightness = pcData.material.rgbBrightness;
        mat.rgbContrast = pcData.material.rgbContrast;
      }

    });
    // Update pointcloud checkboxes in left sidebar.
    setProjectPcs((prev: any) => {
        return prev.map((checkboxItem: any) => {
          checkboxItem.visibile = !!pcDataById[checkboxItem.id]?.visible;
          return checkboxItem;
        });
      }
    )
  }

  /**
   * Get view position and target for Orto scene json_data.
   */
  static getViewData(viewer: any) {
    const camera = viewer.scene.getActiveCamera();
    const view = viewer.scene.view;
    const position = camera.position.toArray();
    const target = view.getPivot().toArray();
    const transitionDuration = DEFAULTS.transitionDuration;
    return { position, target, transitionDuration };
  }

  /**
   * Set view position and target.
   *
   * @param viewer
   *   Potree viewer instance.
   * @param viewData
   *   View position + target from Orto scene json_data.
   */
  static setViewData(viewer: any, viewData: any) {
    viewer.scene.view.setView(
      viewData.position,
      viewData.target,
      viewData.transitionDuration,
    );
  }

  /**
   * Get volumes data for Orto scene json_data.
   */
  static getVolumesData(viewer: any) {
    return viewer.scene.volumes.map((volume: any) => {
      return {
        position: volume.position,
        rotation: volume.rotation,
        scale: volume.scale,
        clip: volume.clip,
        visible: volume.visible,
        uuid: volume.uuid,
      };
    });
  }

  // TODO Do we add volumes if they are present in an Orto scene but not in Potree yet?
  static setVolumesData(viewer: any, volumesData: any) {
    const volumeIndicesByUuid: any = {};
    viewer.scene.volumes.forEach((volume: any, index: number) => {
      volumeIndicesByUuid[volume.uuid] = index;
    });

    volumesData.forEach((volumeData: any, index: number) => {
      // This index > 0 thing is needed, because currently volume data in scenes
      // does not have a UUID assigned. We asumed that there will be only one volume
      // - the one with the zero UUID at index 0 - but that is not the case anymore,
      // there will be more. Should be removed the day all scenes are updated with
      // volume UUIDs.
      const volumeIndex = index > 0 ? (volumeIndicesByUuid[volumeData.uuid] ?? false) : 0;
      if (volumeIndex === false) {
        return;
      }

      const clipVolume: any = viewer.scene.volumes[volumeIndex];
      clipVolume.position.set(
        volumeData.position.x,
        volumeData.position.y,
        volumeData.position.z
      );
      clipVolume.scale.set(
        volumeData.scale.x,
        volumeData.scale.y,
        volumeData.scale.z
      );
      clipVolume.rotation.set(
        volumeData.rotation._x,
        volumeData.rotation._y,
        volumeData.rotation._z
      );
      clipVolume.clip = volumeData.clip;
      clipVolume.visible = volumeData.visible;
    });
  }

  static getMeasurementsData(viewer: any) {
    return viewer.scene.measurements.map(OrtoSceneHelper.createMeasurementData);
  }

  /**
   * @see Potree: src/viewer/SaveProject.js - createMeasurementData(measurement).
   */
  static createMeasurementData(measurement: any) {
    return {
      uuid: measurement.uuid,
      name: measurement.name,
      points: measurement.points.map((point: any) => point.position.toArray()),
      showDistances: measurement.showDistances,
      showHorizontalDist: measurement.showHorizontalDist,
      showCoordinates: measurement.showCoordinates,
      showArea: measurement.showArea,
      closed: measurement.closed,
      showAngles: measurement.showAngles,
      showHeight: measurement.showHeight,
      showCircle: measurement.showCircle,
      showAzimuth: measurement.showAzimuth,
      showEdges: measurement.showEdges,
      color: measurement.color.toArray(),
      showElevation: measurement.showElevation,
    }
  }

  static setMeasurementsData(viewer: any, measurementsData: any) {
    viewer.scene.removeMeasurements();
    viewer.loadOrtoScene({ measurements: measurementsData });
  }

}
