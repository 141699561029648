import { GoPlus } from "react-icons/go";
import { MdLogout } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Heading from "../shared/Heading";
import Button from "../shared/Button";
import LanguageSwitcher from "../shared/LanguageSwitcher";
import Container from "../shared/Container";

interface AdminHeaderProps {
  activeProjects?: number;
  archiveProjects?: number;
}

const AdminHeader: React.FC<AdminHeaderProps> = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  if (!auth.token) return null;

  return (
    <header>
      <Container>
        <div className="flex flex-row justify-between items-center gap-4 h-16">
          <div className="flex flex-row items-center gap-4">
            <Heading title="projects" />

            {location.pathname === "/dashboard" && (
              <Button
                icon={GoPlus}
                handleClick={() => navigate("/project/add")}
              />
            )}

            {location.pathname === "/project/add" && (
              <Button
                label="cancel"
                handleClick={() => navigate("/dashboard")}
              />
            )}
          </div>

          <div className="flex flex-row items-center gap-2">
            <LanguageSwitcher />

            <Button
              label="logout"
              handleClick={() => auth.logout({})}
              classNames="hidden sm:flex"
            />

            <Button
              icon={MdLogout}
              handleClick={() => auth.logout({})}
              classNames="flex sm:hidden"
            />
          </div>

          {/* 
        <nav>
          <ul className="hidden sm:flex flex-row gap-6 items-center">
            {activeProjects && activeProjects > 0 ? (
              <li>Active ({activeProjects})</li>
            ) : null}
            {archiveProjects && archiveProjects > 0 ? (
              <li>Archive ({archiveProjects})</li>
            ) : null}
          </ul>
        </nav> 
        */}
        </div>
      </Container>
    </header>
  );
};

export default AdminHeader;
