export const DEFAULTS = {
  seperator: " , ",
  formatDate: "DD.MM.YYYY",
  formatDateTime: "DD.MM.YYYY HH:mm:ss",
  potreeDisplayTab: "model",
  theme: "light",
  /**
   * Default view transition duration.
   */
  transitionDuration: 2000,
};
