import uniqid from "uniqid";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { POTREE_DISPLAY_MODES } from "../../../constants/potree";
import { potreeTools } from "../../../utils/potreeTools";
import { useToggle } from "../../../hooks/useToggle";
import { usePotree } from "../../../hooks/usePotree";
import Wrapper from "../shared/Wrapper";
import AddScene from "../scenes/AddScene";
import Authenticated from "../shared/Authenticated";
import { OrtoSceneHelper } from "../../../utils/OrtoSceneHelper";

const TabsBar = () => {
  const { t } = useTranslation();
  const { project, potreeViewer, setProjectPcs } = usePotree();
  const { potreeDisplayMode, setPotreeDisplayMode, setActiveScene } =
    useToggle();

  // On project load or potree display mode change, set
  // first scene of display mode as active
  useEffect(() => {
    if (!project || !potreeViewer) return;

    // Find action method showModel, showGrundriss or showSchnitt
    // depending on currently active display mode
    const activeDisplayModeAction = POTREE_DISPLAY_MODES.find(
      ({ value }) => value === potreeDisplayMode
    )?.action;

    // Set display mode related default configs for camera prospective
    potreeTools[activeDisplayModeAction as string](potreeViewer);

    // Scene of currently active display mode
    const activeDisplayModeScenes =
      project?.field_scenes[potreeDisplayMode]?.scenes;

    // Check if there is a first scene in currently active display mode scenes
    const firstScene = activeDisplayModeScenes?.length
      ? activeDisplayModeScenes[0]
      : null;

    if (firstScene) {
      OrtoSceneHelper.setScene(potreeViewer, firstScene, setProjectPcs);
      setActiveScene(firstScene.id);
    } else {
      setActiveScene(null);
    }
  }, [project, potreeDisplayMode, potreeViewer, setProjectPcs, setActiveScene]);

  if (!potreeViewer) return null;

  return (
    <Wrapper
      elementType="section"
      classNames="z-10 absolute bottom-6 left-1/2 transform -translate-x-1/2 rounded-lg p-2 bg-[#F5F5F5]"
    >
      <ul className="flex flex-row items-center gap-3">
        {POTREE_DISPLAY_MODES.map((displayMode) => {
          const label = POTREE_DISPLAY_MODES.find(
            ({ value }) => value === displayMode.value
          )?.value;

          return (
            <li
              key={uniqid()}
              onClick={() => setPotreeDisplayMode(displayMode.value)}
              className={`capitalize transition cursor-pointer px-4 py-2 rounded-lg ${
                potreeDisplayMode === displayMode.value
                  ? "bg-primary text-white"
                  : "text-[#CDCDCD]"
              }`}
            >
              {t(`${label}`)}
            </li>
          );
        })}
        <Authenticated>
          <AddScene />
        </Authenticated>
      </ul>
    </Wrapper>
  );
};

export default TabsBar;
